import {Object3D} from 'three';
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader';
import createStage from '../../3D/stage';
import doubleText from '../../3D/doubleText';
import {MessageLine} from './Text3D.types';
import realSize from '../../3D/realSize';

type RuntimeArgs = {id: string};
type InitArgs = {fontUrl: string; message: MessageLine[]};

const init =
	({fontUrl, message}: InitArgs) =>
	({id}: RuntimeArgs) => {
		const container = window.document.getElementById(id);
		if (!container) throw new Error(`HTML element #${id} not found`);

		const cameraPos = {
			x: 0,
			y: 100,
			z: 2000,
		};
		const {add, animate, destroy, render, cameraZoom} = createStage({
			container,
			background: 0xffffff,
			cameraPos,
		});

		const loader = new FontLoader();
		loader.load(fontUrl, font => {
			let maxWidth = 0;
			// let maxHeight = 0;
			message
				.map(({text, primaryColor, secondaryColor, size, moveY}: MessageLine) => {
					const shapes = font.generateShapes(text, size);
					const {elements, maxWidth: maxW} = doubleText({
						primaryColor,
						secondaryColor,
						shapes,
						yPos: size / 2 + moveY,
					});
					maxWidth = Math.max(maxWidth, maxW);
					return elements;
				})
				.flat()
				.forEach((element: Object3D) => {
					add(element);
				});
			cameraZoom(realSize(container.clientWidth) / maxWidth);
			render();
		});

		setTimeout(animate, 3000);

		const exit = () => destroy();
		return {exit};
	};

export default init;
