import {Color, Object3D, PerspectiveCamera, Scene, WebGLRenderer} from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

export interface StageArgs {
	container: HTMLElement;
	background: number;
	cameraPos: {
		x: number;
		y: number;
		z: number;
	};
}

const createStage = ({container, cameraPos, background}: StageArgs) => {
	let animationRequest: number;

	const scene = new Scene();
	scene.background = new Color(background);
	const camera = new PerspectiveCamera(
		45,
		container.clientWidth / container.clientHeight,
		1,
		20000
	);
	camera.position.set(cameraPos.x, cameraPos.y, cameraPos.z);
	camera.rotateX(2);

	const renderer = new WebGLRenderer({antialias: true});
	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.setSize(container.clientWidth, container.clientHeight);
	container.appendChild(renderer.domElement);

	const render = () => {
		// debugSize(container);
		renderer.render(scene, camera);
	};

	const controls = new OrbitControls(camera, renderer.domElement);
	controls.minZoom = 10;
	controls.maxZoom = 20000;
	controls.target.set(cameraPos.x, cameraPos.y, 0);
	controls.autoRotate = true;
	controls.autoRotateSpeed = 1.5;
	controls.update();

	const onResize = () => {
		renderer.setSize(container.clientWidth, container.clientHeight);
		camera.aspect = container.clientWidth / container.clientHeight;
		camera.updateProjectionMatrix();
		render();
	};

	const animate = () => {
		animationRequest = requestAnimationFrame(animate);
		// required if controls.enableDamping or controls.autoRotate are set to true
		controls.update();
		render();
	};

	const stopAnimation = () => cancelAnimationFrame(animationRequest);

	const onOrientationChanged = () => window.location.reload();

	window.addEventListener('resize', onResize);
	window.addEventListener('orientationchange', onOrientationChanged, true);
	controls.addEventListener('change', render);

	return {
		animate,
		stopAnimation,
		destroy: () => {
			stopAnimation();
			window.removeEventListener('resize', onResize);
			window.removeEventListener('orientationchange', onOrientationChanged, true);
			controls.removeEventListener('change', render);
			container.removeChild(renderer.domElement);
		},
		render,
		add: (obj: Object3D) => scene.add(obj),
		remove: (obj: Object3D) => scene.remove(obj),
		cameraZoom: (zoom: number) => {
			camera.zoom = zoom;
			camera.updateProjectionMatrix();
			render();
		},
	};
};

export default createStage;
