import React, {useEffect} from 'react';
import {Stage3DProps} from './Stage3D.types';

const DEFAULTS: Stage3DProps = {
	htmlID: 'stage3D',
	initStage: args => {
		throw new Error('Initialize method not provided');
	},
};

const Stage3D = (props: Stage3DProps) => {
	const {htmlID = DEFAULTS.htmlID, className, initStage = DEFAULTS.initStage} = props;

	useEffect(() => {
		if (typeof initStage !== 'function') return;

		const stage = initStage({id: htmlID});
		return () => stage.exit();
	}, [htmlID, initStage]);

	return (
		<div
			id={htmlID}
			className={className}></div>
	);
};

export default Stage3D;
