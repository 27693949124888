import {
	BufferGeometry,
	DoubleSide,
	Line,
	LineBasicMaterial,
	Mesh,
	MeshBasicMaterial,
	Object3D,
	Shape,
	ShapeGeometry,
} from 'three';

export interface DoubleTextArgs {
	primaryColor: number;
	secondaryColor: number;
	shapes: Shape[];
	yPos?: number;
	distance?: number;
}

const doubleText = ({
	primaryColor,
	secondaryColor,
	shapes,
	yPos = 0,
	distance = -150,
}: DoubleTextArgs) => {
	const matDark = new LineBasicMaterial({
		color: primaryColor,
		side: DoubleSide,
		opacity: 0.4,
	});
	const matLite = new MeshBasicMaterial({
		color: secondaryColor,
		transparent: true,
		opacity: 0.6,
		side: DoubleSide,
	});

	const geometry = new ShapeGeometry(shapes);
	geometry.computeBoundingBox();
	if (!geometry.boundingBox)
		return {
			elements: [],
			maxWidth: 0,
		};

	const maxWidth = geometry.boundingBox.max.x;
	const xMid = -0.5 * (maxWidth - geometry.boundingBox.min.x);
	geometry.translate(xMid, yPos, 0);

	const text = new Mesh(geometry, matLite);
	text.position.z = distance;

	const holeShapes = [];
	for (let i = 0; i < shapes.length; i++) {
		const shape = shapes[i];
		if (shape.holes && shape.holes.length > 0) {
			for (let j = 0; j < shape.holes.length; j++) {
				const hole = shape.holes[j];
				holeShapes.push(hole);
			}
		}
	}
	// @ts-ignore
	// eslint-disable-next-line prefer-spread
	shapes.push.apply(shapes, holeShapes);

	const lineText = new Object3D();
	for (let i = 0; i < shapes.length; i++) {
		const shape = shapes[i];
		const points = shape.getPoints();
		const geometry = new BufferGeometry().setFromPoints(points);
		geometry.translate(xMid, yPos, 0);
		const lineMesh = new Line(geometry, matDark);
		lineText.add(lineMesh);
	}

	return {
		elements: [text, lineText],
		maxWidth,
	};
};

export default doubleText;
