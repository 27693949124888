import React from 'react';
import './App.scss';
import Text3D from './components/Text3D';
import {MessageLine} from './components/Text3D/Text3D.types';
import {Alert} from '@mui/material';

function App() {
	const msgLines: MessageLine[] = [
		{
			text: 'Rafal Nagrodzki',
			primaryColor: 0x006699,
			secondaryColor: 0x006699,
			size: 100,
			moveY: 100,
		},
		{
			text: 'rafal@sky4me.pl',
			primaryColor: 0x666666,
			secondaryColor: 0x666666,
			size: 70,
			moveY: 0,
		},
		{
			text: 'Software Architect/Engineer',
			primaryColor: 0x666666,
			secondaryColor: 0x666666,
			size: 40,
			moveY: -80,
		},
	];
	return (
		<div className='App'>
			<Text3D
				fontUrl='assets/Sofia_Sans_Bold.json'
				lines={msgLines}
				className='stage3D'
			/>
			<Alert
				severity='info'
				className='alert'>
				Use touchpad/mouse to control the animation.
			</Alert>
		</div>
	);
}

export default App;
