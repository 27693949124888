import Stage3D from '../Stage3D';
import initScript from './intro3DScript';
import './Text3D.styles.scss';
import {Text3DProps} from './Text3D.types';

const Text3D = (props: Text3DProps) => {
	const {fontUrl, lines, className} = props;
	return (
		<Stage3D
			htmlID='Stage3D'
			initStage={initScript({fontUrl, message: lines})}
			className={className}
		/>
	);
};

export default Text3D;
